import { useCallback } from "react"
import { useMangoPay } from "./useMangoPay"
import { MangoPayCardRegistrationPayload, useMangoPayCardRegistration } from "./useMangoPayCardRegistration"

export const useMangoPayInit = () => {
    
    const mangoPay = useMangoPay()
    const mangoPayRegistration = useMangoPayCardRegistration()

    const init = useCallback(async (params: MangoPayCardRegistrationPayload): Promise<any> => {
        const response = await mangoPayRegistration({
            CardType: params.CardType,
            Currency: params.Currency,
            UserId: params.UserId,
        })

        mangoPay.cardRegistration.init({
            cardRegistrationURL: response.CardRegistrationURL, 
            preregistrationData: response.PreregistrationData, 
            accessKey: response.AccessKey,
            Id: response.Id
        });

        return mangoPay
    }, [])

    return init
}
