import mangoPay from 'mangopay-cardregistration-js-kit'
import { useMemo } from 'react'


export const useMangoPay = () => {
    return useMemo(() => {
        mangoPay.cardRegistration.baseURL = process.env.REACT_APP_MONGOPAY_API_URL;
        mangoPay.cardRegistration.clientId = process.env.REACT_APP_MONGOPAY_CLIENT_ID;
        return mangoPay
    }, [])
}
