import { useCallback } from 'react';
import { useMangoPayService } from './../data/mangopay/useMangoPayService';

export const useMangoPayOAuth2 = () => {

    const service = useMangoPayService()

    return useCallback(async () => {
        await service.ensureAccessToken();
        return service.getOAuthHeaders();
    }, [])
}

