import { useCallback } from "react"
import { useMangoPayUrl } from "./useMangoPayUrl"
import { useMangoPayOAuth2 } from "./useMangoPayOAuth2"

export const useMangoPayRequest = () => {

    const apiUrl = useMangoPayUrl()
    const getHeader = useMangoPayOAuth2()
    
    return useCallback(async <TData = Record<string, any>, TResponse = unknown>(payload: Request<TData>): Promise<TResponse> => {
        const authorizationHeader = await getHeader()
        return fetch(`${apiUrl}/${payload.apiUrl}`, {
            method: payload.method || 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authorizationHeader[0].value
            },
            body: JSON.stringify(payload.payload)
        }).then((response) => 
            {
                if (response.status !== 204 ) {
                    return response.json()
                }

                return
            }
        )
    }, [])
}

export type Request<TData = Record<string, any>> = {
    method?: string
    apiUrl: string
    payload?: TData
}
