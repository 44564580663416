import { TCustomerUpdatePayload} from '@ephatta/services';
import { useTranslation } from 'react-i18next';
import { useEphattaMutation } from '../../..';
import { useCustomerService } from './useCustomerService';

export const useCustomerUpdate = () => {
    const service = useCustomerService()
    const {t} = useTranslation()
    
    return useEphattaMutation(
        ( payload : TCustomerUpdatePayload) => service.update(payload) ,
        {
            successMessage: t("Page.MyProfile.PersonalInformation.Success.Message"),
            log(data?, variables?) {
                let message = []
                if(variables?.isMember){
                    message.push(`Statut Membre activé pour l'utilisateur <a href="/users/${variables?.userId}"> ${variables?.userId} </a>.`)
                } else if(variables?.isMember === false){
                    message.push(`Statut Membre désactivé pour l'utilisateur <a href="/users/${variables?.userId}"> ${variables?.userId} </a>.`)
                } else if(variables?.optimNewsletter){
                    message.push(`Notification via email activé pour l'utilisateur <a href="/users/${variables?.userId}"> ${variables?.userId} </a>.`)
                } else if(variables?.optimNewsletter === false){
                    message.push(`Notification via email désactivé pour l'utilisateur <a href="/users/${variables?.userId}"> ${variables?.userId} </a>.`)
                }
                if(message?.length){
                    return message.toString()
                }
                return false
            },
        }
    )
} 

