import { TypeKycDocumentStatus, useTranslations } from "@ephatta/components";
import { Box, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AddIcon } from "components/icons/AddIcon";
import { ReactElement, useMemo } from "react";
import { LoadingIcon, ProfileAddOutlineIcon, SuccessIcon } from "../icons/ProfileIcons";


const IdentityBox = ({ title, subtitle, startIcon, docStatus, disabled, onIconClick }: IdentityBoxProps) => {

    const [
        ADD_IDENTITY_MESSAGE,
        ADD_IDENTITY_ERROR,
    ] = useTranslations(i18nKeys)

    const isSmall = useMediaQuery("(max-width:600px)");

    const theme = useTheme()
    const borderColor = useMemo(() => {
        if (docStatus === 'REFUSED') {
            return theme.palette.error.main
        }

        return '#DEE2E5'
    }, [docStatus])

    const handleIconClick = () => {
        if (docStatus === 'EMPTY' || docStatus === 'CREATED' || docStatus === 'REFUSED') {
            onIconClick?.()
        }
    }

    return (
        <Box>
            <Box
                sx={{
                    border: `2px solid ${borderColor}`,
                    borderRadius: "5px",
                    transition: 'box-shadow 0.3s ease-in-out',
                    cursor: "pointer",
                    "&:hover": {
                        '&:hover': {
                            boxShadow: '0 1px 0px 0 #80cab1, 0 0 0 8px #80cab1'
                        }
                    }
                }}
                paddingY={2}
                paddingLeft={2}
                paddingRight={3}
                maxWidth="300px"
                mt="25px"
                onClick={handleIconClick}
            >
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    spacing={4}
                >
                    <Box>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            spacing={3}
                        >
                            <Box>
                                {startIcon}
                            </Box>
                            <Box>
                                <Stack
                                    spacing={1}
                                >
                                    <Typography
                                        fontSize={isSmall ? '11px' : '16px'}
                                        fontWeight={'bold'}
                                        color={'primary'}
                                        textTransform={'uppercase'}
                                    >
                                        {title}
                                    </Typography>
                                    <Typography
                                        fontWeight={500}
                                        fontSize={isSmall ? '12px' : "18px"}
                                        line-height={"21px"}
                                        color={'primary'}
                                    >
                                        {subtitle}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                    <Box>
                        <IconButton
                            color="secondary"
                            disabled={disabled}
                        >
                            {
                                docStatus === 'VALIDATED' &&
                                <SuccessIcon
                                    width={isSmall ? 26 : undefined}
                                    height={isSmall ? 20 : undefined}
                                />
                            }
                            {
                                docStatus === 'VALIDATION_ASKED' &&
                                <LoadingIcon
                                    width={isSmall ? 26 : undefined}
                                    height={isSmall ? 20 : undefined}
                                />
                            }
                            {
                                (docStatus === 'REFUSED' || docStatus === 'EMPTY' || docStatus == 'CREATED') &&
                                <AddIcon
                                    width={isSmall ? 26 : undefined}
                                    height={isSmall ? 20 : undefined}
                                />
                            }
                        </IconButton>
                    </Box>

                </Stack>
            </Box>


            <Box>

                {/* {
                   (docStatus == 'CREATED' || docStatus == 'EMPTY')  &&
                    <Typography
                        variant="h4"
                        fontWeight={'bold'}
                        color={'secondary'}
                        mt={1}
                    >
                        {ADD_IDENTITY_MESSAGE.replace('$1', title)}
                    </Typography>
                } */}
                {
                    docStatus == 'REFUSED' &&
                    <Typography
                        variant="h4"
                        fontWeight={'bold'}
                        color='error'
                        mt={1}
                    >
                        <>
                            <img src="/assets/img/icon/times.svg" style={{ height: 12, marginRight: '5px' }} />
                            {ADD_IDENTITY_ERROR}
                        </>
                    </Typography>
                }

            </Box>
        </Box>
    )
}

export type IdentityBoxProps = {
    title: string,
    subtitle: string,
    startIcon: ReactElement,
    docStatus: TypeKycDocumentStatus
    disabled?: boolean
    onIconClick?: () => void
}

const i18nKeys = [
    "Page.MyProfile.Identification.AddCardIdentityMessage.Label",
    "Page.MyProfile.Identification.AddCardIdentityError.Label"
]

export default IdentityBox;