import {
    createTheme,
    ThemeProvider,
    Theme,
    ThemeOptions
} from '@mui/material';
import React, { useEffect, useMemo } from 'react'
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter } from 'react-router-dom'
import { MutableSnapshot, RecoilRoot, RecoilState } from "recoil"
import { config, DefaultTheme } from "../../config"
import { Shell, ShellProps } from './Shell'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import './i18n'
import { MainLoader } from 'components/progress/MainLoader';
import { frFR, enUS } from '@mui/x-data-grid';
import i18n from "i18next";
import { MsalProvider } from '@azure/msal-react';
import { useMsalInstance } from 'components/oauth';
import moment from 'moment';
import "moment/locale/fr"
import { GenericSuspenseWrapper } from '..';
import { RoutesState } from 'state/RoutesState';
declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}

export const Root = ({ initialState, ...props }: Props) => {

    const getMsalInstance = useMsalInstance()
    const queryClient = new QueryClient({
        defaultOptions: config.query,
    })

    useEffect(() => {
        moment.locale(i18n.language)
    }, [i18n])


    const locale = useMemo(() => {
        if (i18n.language === 'en') {
            return enUS
        }
        return frFR
    }, [i18n])

    const initializeState: (mutableSnapshot: MutableSnapshot) => void = ({ set }) => {
        initialState?.forEach((entry) => {
            set(entry.state, entry.value);
        });

        props.routes && set(RoutesState, props.routes)
    }

    const wrapMsalProvider = (children: React.ReactNode) => {
        const msalInstance = getMsalInstance()
        return (
            <MsalProvider instance={msalInstance}>
                { children }
            </MsalProvider>
        )
    }

    return (
        <RecoilRoot initializeState={initializeState}>
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ThemeProvider theme={createTheme({ ...DefaultTheme, ...props.theme }, locale)}>
                        <GenericSuspenseWrapper fallback={<MainLoader sx={{ height: '100vh' }} />}>
                            <BrowserRouter>
                                {
                                    wrapMsalProvider(
                                        <Shell {...props} />
                                    )
                                }
                            </BrowserRouter>
                        </GenericSuspenseWrapper>
                    </ThemeProvider>
                </LocalizationProvider>
            </QueryClientProvider>
        </RecoilRoot>
    );
}

export type AppInitialState = {
    state: RecoilState<any>,
    value: any
}

type Props =
    & ShellProps
    & {
        theme?: ThemeOptions
        initialState?: Array<AppInitialState>
    }
