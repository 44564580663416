var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as _ from 'lodash';
import Toolkit from './Toolkit';
import ToolKit from './Toolkit';
var tokenKey = 'token';
var establishmentKey = 'selectedEstablishmentId';
var isJsonContentType = function (response) {
    var contentType = response.headers.get('content-type');
    if (!contentType) {
        return false;
    }
    return !!(contentType &&
        (contentType.toLowerCase().indexOf('application/json') >= 0)
        || contentType.toLowerCase().indexOf('application/problem+json') >= 0);
};
var generateHeaders = function (defaultHeaders, options) { return __awaiter(void 0, void 0, void 0, function () {
    var headers;
    return __generator(this, function (_a) {
        headers = defaultHeaders;
        if (ToolKit.isArray(options.headers)) {
            options.headers.forEach(function (header) {
                if (ToolKit.isUndefinedOrNull(header) ||
                    !ToolKit.isString(header.key)) {
                    throw new Error('Invalid header format');
                }
                if (!ToolKit.isEmpty(header.value))
                    delete headers[header.key];
                headers[header.key] = header.value;
                if (ToolKit.isEmpty(header.value))
                    delete headers[header.key];
                // TODO: Check if good behavior
            });
        }
        return [2 /*return*/, headers];
    });
}); };
var isContentTypeOverrided = function (headers) {
    return ToolKit.isArray(headers) && headers.map(function (header) { return header.key.toLowerCase(); }).indexOf('content-type') >= 0;
};
var sendRequest = function (url, method, options) { return __awaiter(void 0, void 0, void 0, function () {
    var accessToken, response, headers, initData, contentTypeOverrided, fetchResponse, _a, paramString, urlObject, strStatus, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!ToolKit.isString(url))
                    throw new TypeError('input url is not valid.');
                if (!['GET', 'POST', 'PUT', 'DELETE'].includes(method))
                    throw new TypeError('input method is not valid.');
                if (ToolKit.isUndefinedOrNull(options))
                    throw new TypeError('input options are not valid.');
                accessToken = getAccessToken();
                response = new HttpResponse();
                return [4 /*yield*/, generateHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': accessToken ? "Bearer ".concat(accessToken) : ''
                    }, options)];
            case 1:
                headers = _c.sent();
                initData = { method: method.toLowerCase(), headers: headers };
                contentTypeOverrided = isContentTypeOverrided(options.headers);
                _a = method;
                switch (_a) {
                    case 'GET': return [3 /*break*/, 2];
                    case 'DELETE': return [3 /*break*/, 2];
                    case 'POST': return [3 /*break*/, 4];
                    case 'PUT': return [3 /*break*/, 4];
                }
                return [3 /*break*/, 6];
            case 2:
                paramString = _.isEmpty(options.params) ? "" : "?".concat(Toolkit === null || Toolkit === void 0 ? void 0 : Toolkit.encodeUrlParams(options.params));
                urlObject = url + paramString;
                return [4 /*yield*/, fetch(urlObject, initData)];
            case 3:
                fetchResponse = _c.sent();
                return [3 /*break*/, 7];
            case 4:
                initData.body = contentTypeOverrided ? options.data : JSON.stringify(options.data);
                return [4 /*yield*/, fetch(url, initData)];
            case 5:
                fetchResponse = _c.sent();
                return [3 /*break*/, 7];
            case 6: throw new Error('Unhandled http method.');
            case 7:
                response.status = fetchResponse.status;
                strStatus = fetchResponse.status.toString();
                if (!isJsonContentType(fetchResponse)) return [3 /*break*/, 9];
                _b = response;
                return [4 /*yield*/, fetchResponse.json()];
            case 8:
                _b.data = _c.sent();
                return [3 /*break*/, 10];
            case 9:
                response.data = fetchResponse;
                _c.label = 10;
            case 10:
                if (strStatus.startsWith('4') || strStatus.startsWith('5') || strStatus.startsWith('6'))
                    throw response; // HTTP Codes 4XX and 5XX trigger are categorized as error
                return [2 /*return*/, response];
        }
    });
}); };
export var setAccessToken = function (accessToken) {
    localStorage.setItem(tokenKey, accessToken);
};
export var getAccessToken = function () {
    return localStorage.getItem(tokenKey);
};
export var setLocalStorageEstablishment = function (value) {
    localStorage.setItem(establishmentKey, value || '');
};
export var getLocalStorageEstablishment = function () {
    return localStorage.getItem(establishmentKey);
};
var HttpResponse = /** @class */ (function () {
    function HttpResponse() {
        this.status = -1;
        this.data = null;
        this.error = null;
    }
    return HttpResponse;
}());
export { HttpResponse };
var HttpService = /** @class */ (function () {
    function HttpService() {
    }
    var _a;
    _a = HttpService;
    HttpService.get = function (url, params, options) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(_a, function (_b) {
            return [2 /*return*/, (sendRequest(url, 'GET', {
                    params: params,
                    token: !ToolKit.isUndefinedOrNull(options) ? options.token : undefined,
                    headers: !ToolKit.isUndefinedOrNull(options) ? options.headers : undefined
                }))];
        });
    }); };
    HttpService.post = function (url, data, options) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(_a, function (_b) {
            return [2 /*return*/, sendRequest(url, 'POST', {
                    data: data,
                    token: !ToolKit.isUndefinedOrNull(options) ? options.token : undefined,
                    headers: !ToolKit.isUndefinedOrNull(options) ? options.headers : undefined
                })];
        });
    }); };
    HttpService.put = function (url, data, options) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(_a, function (_b) {
            return [2 /*return*/, (sendRequest(url, 'PUT', {
                    data: data,
                    token: !ToolKit.isUndefinedOrNull(options) ? options.token : undefined,
                    headers: !ToolKit.isUndefinedOrNull(options) ? options.headers : undefined
                }))];
        });
    }); };
    HttpService.delete = function (url, params, options) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(_a, function (_b) {
            return [2 /*return*/, (sendRequest(url, 'DELETE', {
                    params: params,
                    token: !ToolKit.isUndefinedOrNull(options) ? options.token : undefined,
                    headers: !ToolKit.isUndefinedOrNull(options) ? options.headers : undefined
                }))];
        });
    }); };
    return HttpService;
}());
export { HttpService };
/**
 * Check GET query and return it as object of key value
 * @protected
 * @param {String} uri
 * @returns {Object}
 */
export var getUriQuery = function (uri) {
    var search_params = new URLSearchParams(uri.replace(/.*\?(.*)/g, '$1'));
    var result = {};
    search_params.forEach(function (value, key, parent) {
        result[key.replace(/\[\]$/, '')] = /\[\]$/.test(key)
            ? parent.getAll(key).map(function (item) { return (isValidJson(item) ? JSON.parse(item) : item); })
            : (isValidJson(value) ? JSON.parse(value) : value);
    });
    return result;
};
/**
* Transform object to uri GET query
* @param {object} data
*/
export var getUrlEncoded = function (data) {
    var str = '';
    for (var prop in data) {
        if (Array.isArray(data[prop])) {
            var adr = data[prop];
            adr.forEach(function (item) {
                var val = typeof item === 'string' ? item : JSON.stringify(item);
                str += "".concat(prop, "[]=").concat(val, "&");
            });
        }
        else if (data[prop]) {
            var val = typeof data[prop] === 'string' ? data[prop] : JSON.stringify(data[prop]);
            str += "".concat(prop, "=").concat(val, "&");
        }
    }
    return str.substring(0, str.length - 1);
};
/**
 * Check if string is a valid json
 * @param str
 * @returns result
 */
export var isValidJson = function (str) {
    try {
        JSON.parse(str);
        return true;
    }
    catch (e) {
        return false;
    }
};
