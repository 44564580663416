import BoxBorderedItem from "../components/BoxBorderedItem";
import { Box, FormHelperText, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import IdentityBox, { IdentityBoxProps } from "../components/IdentityBox";
import {
    ProfileAddOutlineIcon,
    ProfileContactFilledIcon,
    ProfileContactOutlineIcon,
    ProfileWebFilledIcon,
    LoadingIcon,
    SuccessIcon
} from "../icons/ProfileIcons";
import CloseIcon from '@mui/icons-material/Close';
import { TypeKycDocumentStatus, useCustomerDetails, useEnv, useFormData, useListKycDocumentByUser, useMangoPayUploadKYCDocuments, useRuntimeEnv, useRuntimeEnvInvalidate, useTranslations } from "@ephatta/components";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { TClientIdentity, TCustomerBankAccount } from "@ephatta/services";
import _ from "lodash";
import { PopInDowloadIdentity } from "../components/PopInDowloadIdentity";
import PassportBox from "../components/PassportBox";
import { PasswordOutlined } from "@mui/icons-material";

const Identification = () => {

    // ====== MEDIA QUERY ===========
    const isSmall = useMediaQuery("(max-width:600px)");
    const isMedium = useMediaQuery("(max-width:900px)");
    // ======= END MEDIA QUERY ======

    const [
        MY_IDENTIFICATION_TITLE,
        CARD_IDENTITY_TITLE,
        DRIVER_LICENSE_TITLE,
        PASSPORT_IDENTITY_TITLE,
        CARD_IDENTITY_DESCRIPTION,
        RECTO,
        CARD_IDENTITY_DISABLED_ERROR,
        ADD_IDENTITY_MESSAGE,
        ADD_IDENTITY_ERROR,
    ] = useTranslations(i18nKeys)

    const [showError, setShowError] = useState(false)
    const env = useRuntimeEnv<TClientIdentity | null>()
    const invalidateEnv = useRuntimeEnvInvalidate()
    const [openDownloadIdentityDialog, setOpenDownloadIdentityDialog] = useState<{ status: boolean, type: TypePiece }>({ status: false, type: undefined })
    const { data, invalidateQuery } = useCustomerDetails(env?.userId!, true)

    const { data: listKycDocumentsData, invalidateQuery: invalidateListKyc } = useListKycDocumentByUser({ userId: data?.mangopayAccount?.id })

    const listKycDocuments = useMemo(()=>{
        if(_.isArray(listKycDocumentsData)){
            return listKycDocumentsData
        } 
        return []
    },[listKycDocumentsData])
    
    const isMangoPayNull = useMemo(() => _.isNil(data?.mangopayAccount), [data])

    const handleKycSubmited = () => {
        invalidateListKyc()
        invalidateEnv()
        setOpenDownloadIdentityDialog({ status: false, type: undefined })
    }

    const handleIconClick = (type: TypePiece) => {
        !isMangoPayNull && setOpenDownloadIdentityDialog({ status: true, type })
    }

    const hasMissingInformation = useMemo(() => {
        return requiredInfos.some((key) => _.isEmpty((data?.customer as any)?.[key]?.trim() || ''))
    }, [data])

    const identityBoxRef = useRef()

    const handleIdentityClick = () => {
        if (isMangoPayNull) {
            (identityBoxRef.current as any).scrollIntoView({ behavior: 'smooth', block: "center" })
            setShowError(true)
        }
    }

    const docStatus = useMemo(() => {
        return data?.mangopayAccount?.kycDocumentState as IdentityBoxProps['docStatus'] || 'EMPTY'
    }, [data])

    return (
        <Box ref={identityBoxRef}>

            <BoxBorderedItem
                title={MY_IDENTIFICATION_TITLE}
                bordered={false}>
                <Typography
                    variant="h4"
                    fontWeight={'bold'}
                    color={'secondary'}
                    mt={-1}
                    mb={2}
                >
                    {ADD_IDENTITY_MESSAGE}
                </Typography>
                <Grid container spacing={2} display='flex' >

                    <Grid item xs={12} sm={6} onClick={handleIdentityClick}>
                        <IdentityBox
                            title={CARD_IDENTITY_TITLE}
                            subtitle={CARD_IDENTITY_DESCRIPTION}
                            startIcon={(
                                <ProfileContactOutlineIcon
                                    width={isSmall ? 26 : undefined}
                                    height={isSmall ? 20 : undefined}
                                />
                            )}
                            disabled={isMangoPayNull}
                            docStatus={listKycDocuments?.find((doc) => doc.Tag === 'CIN' && doc?.Status !== "REFUSED")?.Status || 'EMPTY'}
                            onIconClick={() => handleIconClick('CIN')}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} onClick={handleIdentityClick}>
                        <IdentityBox
                            title={PASSPORT_IDENTITY_TITLE}
                            subtitle={RECTO}
                            startIcon={(
                                <ProfileContactOutlineIcon
                                    width={isSmall ? 26 : undefined}
                                    height={isSmall ? 20 : undefined}
                                />
                            )}
                            disabled={isMangoPayNull}
                            docStatus={listKycDocuments?.find((doc) => doc.Tag === 'PASSPORT' && doc?.Status !== "REFUSED")?.Status || 'EMPTY'}
                            onIconClick={() => handleIconClick("PASSPORT")}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} onClick={handleIdentityClick}>
                        <IdentityBox
                            title={DRIVER_LICENSE_TITLE}
                            subtitle={RECTO}
                            startIcon={(
                                <ProfileContactOutlineIcon
                                    width={isSmall ? 26 : undefined}
                                    height={isSmall ? 20 : undefined}
                                />
                            )}
                            disabled={isMangoPayNull}
                            docStatus={listKycDocuments?.find((doc) => doc.Tag === 'DRIVER_LICENSE' && doc?.Status !== "REFUSED")?.Status || 'EMPTY'}
                            onIconClick={() => handleIconClick("DRIVER_LICENSE")}
                        />
                    </Grid>

                    {
                        showError && isMangoPayNull &&
                        <Box mt="10px">
                            <FormHelperText error>
                                {CARD_IDENTITY_DISABLED_ERROR}
                            </FormHelperText>
                        </Box>
                    }
                </Grid>
            </BoxBorderedItem>
            {
                openDownloadIdentityDialog.status &&
                <PopInDowloadIdentity
                    open={openDownloadIdentityDialog.status}
                    onClose={() => setOpenDownloadIdentityDialog({ status: false, type: undefined })}
                    userId={data?.mangopayAccount?.id!}
                    onKycSubmitted={handleKycSubmited}
                    type={openDownloadIdentityDialog.type}
                    documentId={listKycDocuments?.find((doc) => doc.Tag === openDownloadIdentityDialog.type && doc?.Status !== "REFUSED")?.Id}
                />
            }
        </Box>
    )
}

export type TypePiece = 'CIN' | 'PASSPORT' | 'DRIVER_LICENSE' | undefined

const i18nKeys = [
    "Page.MyProfile.Identification.Title.Label",
    "Page.MyProfile.Identification.CardIdentity.Label",
    "Page.MyProfile.Identification.Driver.Licence.Label",
    "Page.MyProfile.Identification.Passport.Label",
    "Page.MyProfile.Identification.CardIdentity.Description",
    "Page.MyProfile.Identification.CardIdentity.Recto",
    "Page.MyProfile.Identification.CardIdentity.Disabled.Error",
    "Page.MyProfile.Identification.AddCardIdentityMessage.Label",
    "Page.MyProfile.Identification.AddCardIdentityError.Label"
]

const requiredInfos = [
    'address',
    'city',
    'country',
    // 'region',
    'zipCode'
]


export default Identification;

