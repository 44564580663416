import { useEphattaMutation } from "hooks/utils";
import { useCallback } from "react"
import { useTranslation } from "react-i18next";
import { MangoPayCardRegistrationPayload, MangoPayCardRegistrationResponse } from "./useMangoPayCardRegistration";
import { useMangoPayInit } from "./useMangoPayInit";

export const useMangoPayCardRegister = () => {

    const initMangoPay = useMangoPayInit()
    const { t } = useTranslation()

    const register = useCallback(async (params: MangoPayCardRegister) => {
        const mangoPay = await initMangoPay(params)
        return new Promise<MangoPayCardRegistrationResponse>((resolve, reject) => {
            mangoPay.cardRegistration.registerCard(
                {
                    cardNumber: params.cardNumber,
                    cardExpirationDate: params.cardExpirationDate,
                    cardCvx: params.cardCvx,
                    cardType: params.cardType
                }, 
                function(res: MangoPayCardRegistrationResponse) {
                    resolve(res)
                },
                function(res: any) {
                    reject(res)
                }
            );
        })
    }, [])

    return useEphattaMutation(register, {
        transformErrorMessage: (error: any) => {
            if (errors.includes(error?.ResultMessage)) {
                return t(`Generic.MangoPay.SendCardDetails.${error?.ResultMessage}.Error`)
            }
            return t('Error.Server.CodeUnknown.Message')
        }
    })
}

const errors = [
    'CARD_EXPIRED_ERROR',
    'CARD_NUMBER_FORMAT_ERROR',
    'EXPIRY_DATE_FORMAT_ERROR',
    'PAST_EXPIRY_DATE_ERROR',
    'CVV_FORMAT_ERROR',
]

export type MangoPayCardRegister = MangoPayCardRegistrationPayload & {
    cardNumber: string
    cardExpirationDate: string
    cardCvx: string
    cardType: string
}
